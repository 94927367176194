import React from "react";
import { OutboundLink, PageLink } from "./InternalLink";

/* This example requires Tailwind CSS v2.0+ */
// const navigation = {
//   main: [
//     {
//       name: "Support",
//       href: "mailto:support@erinkellyart.com?&subject=Support - Web",
//     },
//   ],
// };

// export default function Footer() {
//   return (
//     <footer className="bg-white">
//       <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
//         <nav
//           className="-mx-5 -my-2 flex flex-wrap justify-center"
//           aria-label="Footer"
//         >
//           {navigation.main.map((item) => (
//             <div key={item.name} className="px-5 py-2">
//               <a
//                 href={item.href}
//                 className="text-base text-gray-500 hover:text-gray-900"
//               >
//                 {item.name}
//               </a>
//             </div>
//           ))}
//         </nav>
//         <p className="mt-8 text-center text-base text-gray-400">
//           &copy; {new Date().getFullYear()} Anthony DiFiglio
//         </p>
//       </div>
//     </footer>
//   );
// }

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
const navigation = {
  links: [
    // { name: "Home", link: "FOOTER_HOME", href: "/" },
    // OLD
    // {
    //   name: "Who I’ve helped",
    //   link: "FOOTER_WHO_IVE_HELPED",
    //   href: "/who-ive-helped",
    // },
    // {
    //   name: "How we work together",
    //   link: "FOOTER_WORK_TOGETHER",
    //   href: "/work-together",
    // },
    // { name: "About", link: "FOOTER_ABOUT", href: "/about" },
    {
      name: "About",
      link: "NAV_ABOUT",
      href: "/about",
    },
    {
      name: "Approach",
      link: "NAV_APPROACH",
      href: "/approach",
    },  
  ],
  resources: [{ name: "A resource", href: "#" }],
  company: [
    {
      name: "Feelio",
      link: "FOOTER_FEELIO_BLOCKS",
      href: "https://feelioblocks.com/?utm_source=anthony",
    },
    {
      name: "Authentic Movements",
      link: "FOOTER_AUTHENTIC_MOVEMENTS",
      href: "https://teach.authenticmovements.com",
    },
  ],
  social: [
    {
      name: "Instagram",
      href: "https://instagram.com/feelioblocks",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
};

export default function Footer() {
  return <></>
  return (
    <footer
      className="bg-white overflow-hidden"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <nav className="-mx-5 -my-2 flex justify-between" aria-label="Footer">
          <div className="flex flex-wrap">
            {navigation.links.map((item) => (
              <div key={item.name} className="px-5 py-2">
                <PageLink
                  key={item.name}
                  href={item.href}
                  link={item.link}
                  className="text-base text-gray-500 hover:text-gray-900"
                >
                  {item.name}
                </PageLink>
              </div>
            ))}
          </div>
          <div className="flex flex-wrap">
            {navigation.company.map((item) => (
              <div key={item.name} className="px-5 py-2">
                <OutboundLink
                  key={item.name}
                  href={item.href}
                  link={item.link}
                  className="text-base text-gray-500 hover:text-gray-900"
                >
                  {item.name}
                </OutboundLink>
              </div>
            ))}
          </div>
        </nav>

        {/* <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2 ">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Anthony
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.links.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Resources
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.resources.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        target="_blank"
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Company
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        target="_blank"
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
            </div>
          </div>
        </div> */}
        <div className="mt-8 border-t border-gray-200 pt-8 flex items-center justify-between">
          <p className="mt-8 text-base text-gray-400 mt-0 order-1">
            &copy; {new Date().getFullYear()} Anthony DiFiglio
          </p>
          <div className="flex space-x-6 order-2">
            {navigation.social.map((item) => (
              <OutboundLink
                key={item.name}
                href={item.href}
                link="INSTAGRAM"
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </OutboundLink>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}
