/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/seo";
import NavBar from "../components/navBar";
import Footer from "../components/Footer";
import Quote from "../components/Quote";
import PrimaryButton from "../components/PrimaryButton";
import { DARK_MODE } from "../constants";

export default function WhoIveHelped(props: PageProps) {
  const mainBg = DARK_MODE ? "bg-indigo-600" : "bg-white"
  const titleText = DARK_MODE ? "text-white" : "text-indigo-600"

  return (
    <div className={mainBg} id="root">
      <SEO
        title="Who I've helped"
        description="Are you struggling with... Stress, Anxiety, Fatigue, Mood?"
      />
      <NavBar {...props} />
      <main>
        <div className="relative py-16 px-4 sm:px-6 lg:px-8 overflow-hidden flex-col flex items-center">
          <div className="max-w-3xl w-full mt-2 leading-8 mb-2 sm:mb-10">
            <h3 className={titleText + " text-3xl font-extrabold tracking-tight sm:text-4xl"}>
              Who I've helped
            </h3>
          </div>
          {quotes.map((quote, index) => (
            <Quote
              title={quote.title}
              quotes={quote.quotes}
              author={quote.author}
              key={quote.title}
              index={index}
            />
          ))}
          <div className="mt-10 sm:mt-20">
            <PrimaryButton analyticsLink="TESTIMONIAL_1_FREE_CHAT" text="Free 15 min Chat" href="/chat" />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

const quotes = [
  {
    title: "I struggled with very bad anxiety",
    quotes: [
      <>
        I’ve been working with Anthony for over a year and a half now. <b>He is incredibly knowledgeable</b> in a lot of different areas, especially training, health, and lifestyle, but he is also very perceptive and, in my experience, knows the right questions to ask, which is why I’ve continued my work with him for so long. There have been many times when he has asked me a question that I didn’t have an answer to at the time and then weeks (or a year) later I show up to our meeting super excited because I’d realized something profound, and it’s all because his question that planted the seed.
      </>,
      <>
        When I started working with Anthony <b>I struggled with very bad anxiety</b>. In a way, I feel like my work with him has helped me take a lot of the things that we talked about and journaled about at the (yoga teacher) training and start applying them to my everyday life.
      </>,
      <>
        I probably would have gone back to my old ways if not for my work with
        Anthony. I can say for certain that{" "}
        <b>I wouldn’t be where I am today without him</b>. I probably would have
        gotten here eventually but it would have taken me far longer. I'm very
        grateful.
      </>,
    ],
    author: "Rebecca",
  },
  {
    title: "I was fairly stressed, worried about physical setbacks, and overwhelmed with my busy life as a working mom",
    quotes: [
      <>
        I have only great things to say about Anthony. <b>I feel like he's an old
        soul with a unique perspective on life that can help a lot of people</b>.
        Before talking to him, I was fairly stressed, worried about some
        physical setbacks I was experiencing post-injury and 
        overwhelmed with my busy life as a working mom of 3.
      </>,
      <>
        <b>I was very frustrated with unexplainable pain & constantly feeling
        injured</b> during exercise, things that{" "}
        my mainstream doctors couldn't explain to me. But although my
        focus was on the physical, we began addressing a lot on the mental side
        of things early on - which was just as much a part of my troubles as the
        physical.
      </>,
      "Anthony has a way of challenging you in a positive and constructive manner that will make you reflect on your beliefs and the reasons for why you're doing things.",
      <>
        On the physical side, Anthony directed me to good resources that taught
        me about pain in addition to sharing what he knew and reframed my
        understanding of pain completely- something I've continued to use and
        even shared with other family members. The mental topics we discussed
        and challenged correlated with the physical training we were doing as
        well - it was all cohesive.
      </>,
      <>
        Anthony is super knowledgeable about anatomy and movement in the body
        and created custom training programs that intelligently progressed me in
        strength and difficulty so that I could get back to doing what I loved.
        Through his programs, I was able to build a base and relearn movements
        that corrected my form and taught me valuable skills I still use. I was
        able to gradually progress to where I could confidently workout on my
        own again <b>without fear and pain</b> - which is exactly what I wanted
        to do.
      </>,
      <>
        Ultimately, <b>I learned a lot about myself and the mind-body relationship</b>
        while I worked with Anthony. I learned about how my fears & stress
        translated into tension in my movement, and he helped me gain back my
        confidence, strength and ability, by meeting me where I was. Anthony was
        always personable, and accessible, and I feel like{" "}
        <b>he truly cared about me and how I was doing</b> - a friend. I never
        felt like he was rushed with our time together.
      </>,
      "I definitely recommend Anthony to anyone who's looking for a unique approach to health and fitness in both body and mind.",
    ],
    author: "Mackenzie",
  },
  {
    title: "I was sleeping 4-5 hours a night, highly stressed, addicted to nicotine, and starving myself until the end of the day",
    quotes: [
      <>
        It’s difficult to express how unique Anthony’s skill set is compared to other professionals I have worked with. Other health professionals stay in one domain and have many blind spots. <b>Anthony has a comprehensive vision and skillset</b> of many things related to health, and some I wasn’t expecting.  His level of detail and attentiveness allowed me to see things that I would have otherwise overlooked.
      </>,
      <>
        Before working with Anthony, <b>I was sleeping 4-5 hours a night, highly stressed, addicted to nicotine, and starving myself</b> until the end of the day. My diet was not consistent or sustainable, which were things I never thought I could change.
      </>,
      <>
        After a few years of us working together, everything has changed. <b>I am breathing with ease, and my nervous system and gut are flowing more freely</b>. I sleep… And I let go of the biggest crutch in my life so far, nicotine. My diet has completely changed, and most importantly it supports my active lifestyle and I can easily do it.
      </>,
      <>
        While I initially came to Anthony to build strength after years of only running and yoga practice, I achieved that and so much more. At first, it was hard to believe some of the changes he suggested would make such an impact on my life, but I can say these very things have transformed my life.
      </>,
    ],
    author: "Ariel",
  },
  {
    title: "I had lost confidence and was running on fumes",
    quotes: [
      "I attended a presentation by Anthony on stress and nutrition after retiring from a demanding career followed by several years as the primary caregiver for elderly parents, all while trying to deal with my own chronic health issues.",
      <>
        When I met Anthony, I was underweight and living with self-imposed
        limitations due primarily to poor gut function. <b>I had lost
        confidence in myself and, frankly, was running on fumes</b>. Anthony and I
        met for what I expected to be a nutritional consultation. I left the
        meeting not only with a plan to do a gut reset through diet
        modification, but also having made a commitment to start weight-training
        one-on-one with Anthony in order to gain 18 pounds. Within six months
        I’d met our weight gain goals and was <b>stronger than I’d ever been</b>
        , dead-lifting double my body weight.
      </>,
      <>
        <b>
          My mood, outlook, and full range of human function were significantly
          improved
        </b>
        . My gut responded to the diet reset and I was able to add back many of
        the foods that once bothered me. My GI function had become predictable
        and I was able to get back to living my life without restriction. I also
        learned breath and mindfulness techniques I can use when issues do
        arise. I should add that after I started working with Anthony,{" "}
        <b>I gained the self-worth</b> needed to seek out professional support
        to address other challenges and am finally living what I believe to be
        my best life.
      </>,
      "Anthony’s practical and powerfully optimistic approach to his work, along with my disciplined commitment to do my part, was the beginning and essential driver in getting my life back on track. Plus, hanging with Anthony is just a really good time!",
    ],
    author: "David",
  },
  {
    title: "I felt sluggish and unbalanced physically & nutritionally",
    quotes: [
      <>
        Before working with Anthony, I experienced severe neck pain and many
        asthma flare-ups. Additionally,{" "}
        <b>I felt sluggish and unbalanced physically & nutritionally</b>.
      </>,
      "I thought this was going to be the way of life as one ages. Therefore, inhalers, Tylenol, and massage therapy became part of my routine regimen. During severe episodes of asthma, rounds of antibiotics, steroids, and breathing treatments were required. It was around the same time when I sadly retired a set of 8-pound dumbbells after an initial misuse (not knowing what I was doing, I had strained my neck and back). After consulting with Anthony, he assured me that I would graduate past the 8-pounders. Due to my lack of skills and strength, I was initially skeptical about what progress could be made. With Anthony’s expertise, coupled with commitment to consistency, I am finding myself exceeding expectations.",
      <>
        Due to targeted exercises and breathwork these past two years,{" "}
        <b>
          the neck pain that required regular visits to the chiro ceased to
          exist
        </b>
        , and I have rarely needed the use of an inhaler. There has been
        significant improvement relating to asthma symptoms as I have not needed
        to take any antibiotics, steroids, or breathing treatments. I feel
        stronger, more fit, and tone.
      </>,
      "Undoubtedly, I would not have been able to achieve any of this on my own so I am eternally grateful for Anthony. Overall, training with him can only be described as a life-altering journey!",
    ],
    author: "Nisha",
  },
  {
    title: "There are things that weren’t even possibilities in my mind before working with Anthony",
    quotes: [
      "There are things that weren’t even possibilities in my mind before working with Anthony. My doctor seems perplexed by my blood tests. I couldn’t be more amazed at what’s still possible with all my prior diagnoses.",
    ],
    author: "Barb",
  },
];
