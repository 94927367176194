import React from "react";
import { isFunction } from "lodash";

interface Props {
  title: string;
  quotes: (string | JSX.Element)[];
  author: string;
  forceOpen?: boolean;
  index?: number;
  hideTitle?: boolean;
  isStagger?: boolean;
}

export default function Quote({
  title,
  quotes,
  author,
  forceOpen,
  index,
  hideTitle,
  isStagger,
}: Props) {
  return (
    <div className="flex items-center max-w-3xl mt-8 mb-8">
      <div
        tabIndex={0}
        className={`collapse ${
          forceOpen ? "collapse-open" : "collapse-arrow"
        } ${
          index && index % 2 == 1 ? "bg-gray-100" : "bg-indigo-200"
          // index && index % 2 == 1
          //   ? " bg-gradient-to-r from-gray-200 to-gray-100"
          //   : " bg-gradient-to-r from-indigo-200 to-indigo-100"
        } ${
          isStagger
            ? index == 0
              ? "ml-0 mr-0 md:-ml-8 md:mr-8 lg:-ml-16 lg:mr-16"
              : index == 1
              ? "ml-0 mr-0"
              : "ml-0 mr-0 md:-mr-8 md:ml-8 lg:-mr-16 lg:ml-16"
            : ""
        } rounded-box rounded-lg`}
      >
        {hideTitle == true ? undefined : (
          <div className="collapse-title text-2xl font-medium">{title} ...</div>
        )}
        <div
          className={`collapse-content ${
            hideTitle == true ? "text-xl mt-3" : ""
          }`}
        >
          {quotes.map((quote, index) => (
            <p className="mb-3" key={index}>
              {index == 0 ? '"' : ""}
              {quote}
              {index == quotes.length - 1 ? '"' : ""}
            </p>
          ))}
          <p>-{author}</p>
        </div>
      </div>
    </div>
  );
}
