import React from "react";
import InternalLink from "./InternalLink";
import { DARK_MODE } from "../constants";

interface Props {
  text: string,
  analyticsLink: string,
  onClick?: () => void,
  href?: string,
}

export default function CalendarButton({ text, analyticsLink, href, onClick }: Props) {
  const bg = DARK_MODE ? "bg-white hover:bg-grey-300" : "bg-indigo-600 hover:bg-indigo-700"
  const textColor = DARK_MODE ? " text-indigo-600" : " text-white"
  return (
    <InternalLink
      link={analyticsLink}
      href={href}
      className={
        // "ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md shadow"
        // "w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md shadow"
        bg + " " + textColor + " flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md md:py-4 md:text-lg md:px-10 rounded-md shadow"
      }
      onClick={onClick}
    >
      {text}
    </InternalLink>
  );
}
